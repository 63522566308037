export const tailoredEmblemsGranted = [
  {
    traits: "0-5",
    emblems: "0",
  },
  {
    traits: "6-7",
    emblems: "1",
  },
  {
    traits: "8-9",
    emblems: "2",
  },
  {
    traits: "10-11",
    emblems: "3",
  },
  {
    traits: "12+",
    emblems: "4",
  },
];

export const emblemChanceByTraitCount = [
  { "0-5": [0.19, 0.19, 0.19, 0.19, 0.19, 0.19] },
  { 6: [0.292, 0.32, 0.368, 0.433, 0.575, 1] },
  { 7: [0.271, 0.292, 0.32, 0.362, 0.433, 0.575] },
  { 8: [0.329, 0.361, 0.404, 0.463, 0.553, 0.702] },
  { 9: [0.304, 0.329, 0.361, 0.404, 0.463, 0.553] },
  { 10: [0.339, 0.37, 0.41, 0.46, 0.528, 0.622] },
  { 11: [0.313, 0.339, 0.37, 0.41, 0.46, 0.528] },
  { "12+": [0.333, 0.364, 0.4, 0.444, 0.5, 0.571] },
];
